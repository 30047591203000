.outline-button111-container {
  display: flex;
  position: relative;
}
.outline-button111-button {
  color: var(--dl-color-gray-black);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-100);
  border-width: 1px;
  padding-left: var(--dl-space-space-doubleunit);
  border-radius: 50px;
  padding-right: var(--dl-space-space-doubleunit);
  padding-bottom: var(--dl-space-space-unit);
  background-color: var(--dl-color-gray-white);
}
